import { METHOD, frontmanZendeskApi } from '@/utils/api'

import { ArticleItem, Pagination, ZendeskRequestParams } from './types'

export type GetSearchArticlesRequest = ZendeskRequestParams

// [DOC] : https://developer.zendesk.com/api-reference/help_center/help-center-api/search/#search-articles
// UPDATED : 2023-11-16
export interface SearchArticleItem extends ArticleItem {
  result_type?: string
  snippet?: string
}

export interface GetSearchArticlesResponse extends Pagination {
  results: SearchArticleItem[]
}

export const getSearchArticles = (query: GetSearchArticlesRequest) => {
  return frontmanZendeskApi<GetSearchArticlesResponse>({
    path: '/api/zendesk/help_center/articles/search',
    method: METHOD.GET,
    query,
    isProxy: true,
  })
}
